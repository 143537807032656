<script>
import vuetableFormatter from '@src/mixins/vuetableFormatters'
import exportPresentationJobTypes from '@src/mixins/exportPresentationJobTypes'

const i18nKey = 'EXPORT_PRESENTATION_JOBS'

export default {
    mixins: [
        vuetableFormatter,
        exportPresentationJobTypes,
    ],
    data() {
        return {
            exportPresentationJobFields: [
                {
                    name: 'filtered_start',
                    title: this.getI18n(i18nKey, 'FIELDS.filtered_start'),
                    sortField: 'filtered_start',
                },
                {
                    name: 'filtered_end',
                    title: this.getI18n(i18nKey, 'FIELDS.filtered_end'),
                    sortField: 'filtered_end',
                },
                {
                    name: 'type',
                    title: this.getI18n(i18nKey, 'FIELDS.type'),
                    order: 'type',
                    formatter: (value) => {
                        const type = this.exportTypes.find(
                            (type) => type.value === value
                        )
                        return type.text
                    },
                },
                {
                    name: 'creator_name',
                    title: this.getI18n(i18nKey, 'FIELDS.creator'),
                    sortField: 'creator_name',
                },
                {
                    name: 'description_slide',
                    title: this.getI18n(i18nKey, 'FIELDS.description_slide'),
                    sortField: 'description_slide',
                    formatter: this.booleanIcons,
                },
                {
                    name: 'description_in_photo_slide',
                    title: this.getI18n(i18nKey, 'FIELDS.description_in_photo_slide'),
                    sortField: 'description_in_photo_slide',
                    formatter: this.booleanIcons,
                },
                {
                    name: 'title_in_photo_slide',
                    title: this.getI18n(i18nKey, 'FIELDS.title_in_photo_slide'),
                    sortField: 'title_in_photo_slide',
                    formatter: this.booleanIcons,
                },
                {
                    name: 'product_category_slide',
                    title: this.getI18n(i18nKey, 'FIELDS.product_category_slide'),
                    sortField: 'product_category_slide',
                    formatter: this.booleanIcons,
                },
                {
                    name: 'order',
                    title: this.getI18n(i18nKey, 'FIELDS.order'),
                    order: 'order',
                    formatter: (value) => {
                        const order = this.exportOrders.find(
                            (order) => order.value === value
                        )
                        return order.text
                    },
                },
                {
                    name: 'started',
                    title: this.getI18n(i18nKey, 'FIELDS.started'),
                    sortField: 'ended_at_date_time',
                },
                {
                    name: 'ended',
                    title: this.getI18n(i18nKey, 'FIELDS.ended'),
                    sortField: 'ended_at_date_time',
                },
                {
                    name: 'file',
                    title: this.getI18n(i18nKey, 'FIELDS.file'),
                },
            ],
        }
    },
}
</script>

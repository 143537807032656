<script>
    import DeleteButton from '@/src/components/DeleteButton'
    import CancelLabelButton from '@/src/components/CancelLabelButton.vue'

    export default {
        components: {
            DeleteButton,
            CancelLabelButton,
        },
        props: {
            busy: {
                type: Boolean,
                required: false,
            },
        },
        data() {
            return {
                handleConfirm: null,
            }
        },
        computed: {
            i18nMessageModal() {
                return this.getI18n('RESPONSES.delete_confirmation_template')
            },
            i18nDescriptionModal() {
                return this.getI18n('COMMON.confirm_delete_template')
            },
            i18nDiscardChanges() {
                return this.getI18n('COMMON.confirm')
            },
        },
        methods: {
            showModal(handleConfirm) {
                this.handleConfirm = handleConfirm
                this.$refs.confirmationDeleteModal.show()
            },
            handleCancel() {
                this.$refs.confirmationDeleteModal.hide();
            },
            confirmDelete() {
                if (this.handleConfirm) {
                    this.handleConfirm();
                }
                this.handleCancel();
            },
        },
    }
</script>

<template>
    <div>
        <b-modal
            id="confirmationDeleteModal"
            ref="confirmationDeleteModal"
            centered
            :no-close-on-backdrop="true"
            :no-enforce-focus="true"
            size="md"
        >
            <template v-slot:modal-header>
                <h4 class="modal-title description">
                    {{ i18nDescriptionModal }}
                </h4>
                <button class="close" @click="handleCancel">
                    <i class="fe-x-circle icon-blue"/>
                </button>
            </template>
            <b-row class="modal-body message">
                {{ i18nMessageModal }}
            </b-row>
            <template v-slot:modal-footer>
                <div class="col text-center ml-3 mr-3">
                    <delete-button
                        class="mb-1"
                        :busy="busy"
                        :title="i18nDiscardChanges"
                        @onClick="confirmDelete"
                    />
                    <cancel-label-button
                        @onClick="handleCancel"
                    />
                </div>
            </template>
        </b-modal>
    </div>
</template>

<template>
    <button
        type="button"
        class="btn btn-pdv btn-pdv-green mr-1"
        href="javascript: void(0);"
        @click="$emit('onClick')"
    >
        <i class="fe-settings mr-1"/>
        {{ getI18n('COMMON.setting') }}
    </button>
</template>

<script>
export default {}
</script>
